// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
// core components
// import CustomProfileHeader from "components/Headers/CustomProfileHeader.js";
import React from "react";

import { adminRoutes } from "../../../endpoints/base.endpoint";
import * as adminEndpoints from "../../../endpoints/admin.endpoint";
import TankCard from "components/Station/TankCard";
import DisabledCheckbox from "components/Station/DisabledCheckbox";
import CommandDescription from "./cmdDescription.json";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function StationDetails() {
  const location = useLocation();
  const station_manage_id = sessionStorage.getItem("station_manage_id");
  const config = {
    header: {
      "Access-Control-Allow-Origin": "*",
      authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  };

  let Routes;
  let endpoints;
  Routes = adminRoutes;
  endpoints = adminEndpoints;
  const [
    { data: getStation, loading: getStationLoading, error: getStationError },
    refreshStation,
  ] = Routes(
    {
      url: endpoints.view_station.path,
      method: endpoints.view_station.method,
      headers: config.header,
      data: { id: station_manage_id },
    },
    { manual: false }
  );
  const isWithGateWay =
    location.pathname.split("/")[2].split("-")[0] === "with";
  return (
    <>
      <SimpleHeader name="Station Details" />

      {getStation && (
        <Container className="mt--6" fluid>
          <Row>
            <Col className="order-lg-1" lg="12">
              <Card className="card-profile">
                <CardHeader className="text-center border-0 pt-4 pt-md-4 pb-0 pb-md-4">
                  <a
                    href={isWithGateWay ? "./with-gateway" : "./station-list"}
                    className="text-white float-left"
                  >
                    <Button size="sm" color="default" type="button">
                      Back
                    </Button>
                  </a>
                  <div className="">
                    <span
                      className={"display-1"}
                      style={{ marginTop: "-1rem" }}
                    >
                      {getStation.stationName}
                    </span>
                    <div className="h1 mt-2">
                      <span className="font-weight-light p-1">
                        {" "}
                        {getStation.stationAddress},
                      </span>
                      <span className="font-weight-light p-1">
                        {" "}
                        {getStation.city} , {getStation.state}
                      </span>
                      <span className="font-weight-light p-1">
                        {" "}
                        {getStation.country} , {getStation.zip}
                      </span>
                    </div>
                  </div>
                </CardHeader>
                {getStation && (
                  <CardBody className="pt-1">
                    <Row>
                      <div className="col-12">
                        <div className="card-profile-stats d-flex justify-content-center"></div>
                      </div>
                    </Row>
                    <Row className="">
                      <Col lg="">
                        <Card>
                          <CardHeader className="h3">
                            Station Details
                          </CardHeader>
                          <CardBody>
                            <Row>
                              <Col lg="6">
                                <div className="mt-2">
                                  <span className="h4 font-weight-light p-1">
                                    {" "}
                                    Phone
                                  </span>{" "}
                                  :{" "}
                                  <a href={"tel:" + getStation.phone}>
                                    {getStation.phone}
                                  </a>
                                </div>
                                <div className="mt-2">
                                  <span className="h4 font-weight-light p-1">
                                    {" "}
                                    Email
                                  </span>{" "}
                                  :{" "}
                                  <a href={"mailto:" + getStation.email}>
                                    {getStation.email}
                                  </a>
                                </div>
                                <div className={"mt-2"}>
                                  <span className={"h4 font-weight-light p-1"}>
                                    Station Contact :{" "}
                                    {getStation.stationContact}
                                  </span>
                                </div>
                                <div className={"mt-2"}>
                                  <span className={"h4 font-weight-light p-1"}>
                                    Facility Id : {getStation.facilityId}
                                  </span>
                                </div>
                                <div className={"mt-2"}>
                                  <span className={"h4 font-weight-light p-1"}>
                                    Owner Id : {getStation.ownerId}
                                  </span>
                                </div>
                                <div className={"mt-2"}>
                                  <span className={"h4 font-weight-light p-1"}>
                                    Latitude :{" "}
                                    {getStation?.location?.coordinates[1]}
                                  </span>
                                </div>
                                <div className={"mt-2"}>
                                  <span className={"h4 font-weight-light p-1"}>
                                    Longitude :{" "}
                                    {getStation?.location?.coordinates[0]}
                                  </span>
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className={"mt-2"}>
                                  <span className={"h4 font-weight-light p-1"}>
                                    Console Type : {getStation.consoleType}
                                  </span>
                                </div>
                                <div className={"mt-2"}>
                                  <span className={"h4 font-weight-light p-1"}>
                                    Dispenser Type : {getStation.dispenserType}
                                  </span>
                                </div>
                                <div className={"mt-2"}>
                                  <span className={"h4 font-weight-light p-1"}>
                                    Owner Name : {getStation.ownerName}
                                  </span>
                                </div>
                                <div className={"mt-2"}>
                                  <span className={"h4 font-weight-light p-1"}>
                                    Manager Name : {getStation.managerName}
                                  </span>
                                </div>
                                <div className={"mt-2"}>
                                  <span className={"h4 font-weight-light p-1"}>
                                    Employee Name : {getStation.employeeName}
                                  </span>
                                </div>
                                <div className={"mt-2"}>
                                  <span className={"h4 font-weight-light p-1"}>
                                    ATG Type : {getStation.ATGtype}
                                  </span>
                                </div>
                                <div className={"mt-2"}>
                                  <span className={"h4 font-weight-light p-1"}>
                                    Place Id : {getStation.placeID}
                                  </span>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      {getStation?.gateway && (
                        <Col lg="4">
                          <Card>
                            <CardHeader className="h3">
                              Gateway Details
                            </CardHeader>
                            <CardBody>
                              <div className={"mt-2"}>
                                <span className={"h4 font-weight-light p-1"}>
                                  Device MAC : {getStation.deviceID}
                                </span>
                              </div>
                              <div className={"mt-2"}>
                                <span className={"h4 font-weight-light p-1"}>
                                  ATG Model : {getStation.ATGModel}
                                </span>
                              </div>
                              <div className={"mt-2"}>
                                <span className={"h4 font-weight-light p-1"}>
                                  Last Connected : {getStation.lastConnectionIP}
                                </span>
                              </div>
                              <div className={"mt-2"}>
                                <span className={"h4 font-weight-light p-1"}>
                                  Hardware Version :{" "}
                                  {getStation?.deviceInfo?.hardwareVersion}
                                </span>
                              </div>
                              <div className={"mt-2"}>
                                <span className={"h4 font-weight-light p-1"}>
                                  Firmware Version :{" "}
                                  {getStation?.deviceInfo?.firmwareVersion}
                                </span>
                              </div>
                              <div className={"mt-2"}>
                                <span className={"h4 font-weight-light p-1"}>
                                  Update Config Pending :{" "}
                                  {getStation.updateConfig ? "True" : "False"}
                                </span>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      )}
                    </Row>
                    <hr className="my-4" />
                    <h6 className="heading-small text-muted mb-4">
                      Tanks Details
                    </h6>
                    <Row>
                      {getStation.tank &&
                        Object.entries(getStation.tank).map((tank) => (
                          <Col sm="6" md="4" lg="3" key={tank[0]}>
                            <TankCard
                              tankNumber={tank[0]}
                              color={tank[1].color}
                              product={tank[1].type}
                              capacity={tank[1].capacity}
                              tankData={getStation.tank_data}
                            />
                          </Col>
                        ))}
                    </Row>

                    <hr className="my-4" />
                    <h6 className="heading-small text-muted mb-4">
                      Device Configuration
                    </h6>
                    {getStation.deviceConfig && (
                      <>
                        <Row>
                          <Col>
                            <h5>Real Time Command</h5>
                            <Table>
                              <thead>
                                <tr>
                                  <th className="font-weight-900">Command</th>
                                  <th className="font-weight-900">Desc.</th>
                                </tr>
                              </thead>
                              <tbody>
                                {getStation.deviceConfig.loop_cmd ? (
                                  getStation.deviceConfig.loop_cmd.map(
                                    (cmd, index) => (
                                      <tr key={index}>
                                        <td>{cmd}</td>
                                        <td>{CommandDescription[cmd]}</td>
                                      </tr>
                                    )
                                  )
                                ) : (
                                  <h6>No Commands Set</h6>
                                )}
                              </tbody>
                            </Table>
                          </Col>

                          <Col>
                            <h5>Interval Command</h5>
                            <Table responsive={true}>
                              <thead>
                                <tr>
                                  <th className="font-weight-900">Command</th>
                                  <th className="font-weight-900">Desc.</th>
                                </tr>
                              </thead>
                              <tbody>
                                {getStation.deviceConfig.interval ? (
                                  getStation.deviceConfig.interval.map(
                                    (cmd, index) => (
                                      <tr key={index}>
                                        <td>{cmd}</td>
                                        <td>{CommandDescription[cmd]}</td>
                                      </tr>
                                    )
                                  )
                                ) : (
                                  <h6>No Commands Set</h6>
                                )}
                              </tbody>
                            </Table>
                          </Col>

                          <Col>
                            <h5>Time Based Command</h5>
                            <Table responsive={true}>
                              <thead>
                                <tr>
                                  <th className="font-weight-900">Command</th>
                                  <th className="font-weight-900">Desc.</th>
                                  <th className="font-weight-900">Print</th>
                                  <th className="font-weight-900">Time</th>
                                </tr>
                              </thead>
                              <tbody>
                                {getStation.deviceConfig.time_cmd ? (
                                  getStation.deviceConfig.time_cmd.cmd.map(
                                    (cmd, index) => {
                                      let printName = cmd[1];
                                      return (
                                        <tr key={index}>
                                          <td>{cmd[0]}</td>
                                          <td>{CommandDescription[cmd[0]]}</td>
                                          <td>
                                            {printName === ""
                                              ? "False"
                                              : "True"}
                                          </td>
                                          <td>
                                            {getStation.deviceConfig.time_cmd.time[
                                              index
                                            ].join(":")}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )
                                ) : (
                                  <h6>No Commands Set</h6>
                                )}
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      </>
                    )}

                    <hr className="my-4" />
                    <h6 className="heading-small text-muted mb-4">
                      Shift Details
                    </h6>
                    {getStation?.shift && (
                      <Row>
                        {Object.keys(getStation?.shift).map((shiftKey) => {
                          const shift = getStation.shift[shiftKey];
                          return (
                            <Col key={shiftKey} md="3">
                              <div className="shift-detail">
                                <p className="font-weight-bold">
                                  Shift {shiftKey}
                                </p>
                                <p>
                                  Status: {shift.status ? "Active" : "Inactive"}
                                </p>
                                <p>Time: {shift.time}</p>
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                    )}

                    <hr className="my-4" />
                    <h6 className="heading-small text-muted mb-4">
                      Subcriptions Details
                    </h6>
                    {getStation.subscription && (
                      <Row>
                        <DisabledCheckbox
                          title="Alarm"
                          isChecked={getStation.subscription.alarm}
                        />
                        <DisabledCheckbox
                          title="Issue"
                          isChecked={getStation.subscription.issue}
                        />
                        <DisabledCheckbox
                          title="WorkOrder"
                          isChecked={getStation.subscription.workOrder}
                        />
                        <DisabledCheckbox
                          title="Inspection"
                          isChecked={getStation.subscription.inspection}
                        />
                        <DisabledCheckbox
                          title="Compliances"
                          isChecked={getStation.subscription.compliance}
                        />
                        <DisabledCheckbox
                          title="Historical Inventory"
                          isChecked={
                            getStation.subscription.historicalInventory
                          }
                        />
                        <DisabledCheckbox
                          title="Shift Report"
                          isChecked={getStation.subscription.shiftReport}
                        />
                        <DisabledCheckbox
                          title="Delivery Report"
                          isChecked={getStation.subscription.deliveryReport}
                        />
                        <DisabledCheckbox
                          title="Generate Report"
                          isChecked={getStation.subscription.generateReport}
                        />
                        <DisabledCheckbox
                          title="Documents"
                          isChecked={getStation.subscription.document}
                        />
                      </Row>
                    )}
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

export default StationDetails;
