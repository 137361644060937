import CustomProfileHeader from "components/Headers/CustomProfileHeader";
import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { adminRoutes } from "../../../endpoints/base.endpoint";
import * as adminEndpoints from "../../../endpoints/admin.endpoint";

const pagination = paginationFactory({
  page: 1,
  sizePerPage: 100,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            defaultValue={"100"}
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

function VendorManagerDetails() {
  const componentRef = React.useRef(null);

  const manager_id = sessionStorage.getItem("vendor_manager_manage_id");
  console.log(manager_id, "qw");

  const config = {
    header: {
      "Access-Control-Allow-Origin": "*",
      authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  };

  let Routes;
  let endpoints;
  Routes = adminRoutes;
  endpoints = adminEndpoints;
  const [
    {
      data: getVendorManager,
      loading: getVendorManagerLoading,
      error: getVendorManagerError,
    },
  ] = Routes({
    url: endpoints.view_vendor_manager.path + "/" + manager_id,
    method: endpoints.view_vendor_manager.method,
    headers: config.header,
  });
  console.log(endpoints.view_vendor_manager.path + "/" + manager_id);

  console.log("get vendor manager", getVendorManager);

  return (
    <>
      <CustomProfileHeader heading={"Vendor Manager Details"} />
      {getVendorManager && (
        <Container className="mt--6" fluid>
          <Row>
            <Col className="order-lg-1" lg="12">
              <Card className="card-profile">
                <CardHeader className="text-center border-0 pt-4 pt-md-4 pb-0 pb-md-4">
                  <div className="row align-items-center">
                    <div className="col-sm-2 col-md-2 col-lg-1">
                      <a
                        href="./manager-list"
                        className="text-white float-left"
                      >
                        <Button size="sm" color="default" type="button">
                          Back
                        </Button>
                      </a>
                    </div>
                    <div className="col-sm-8 col-md-8 col-lg-10">
                      <div className={"display-1 capitalize"}>
                        {getVendorManager.name}
                      </div>
                    </div>
                    <div className="col-sm-2 col-md-2 col-lg-1 align-self-center">
                      <a
                        href={"./vendor-manager-edit"}
                        className="btn btn-warning d-print-none"
                        onClick={(e) =>
                          sessionStorage.setItem(
                            "vendor_manager_edit_id",
                            manager_id
                          )
                        }
                      >
                        Edit
                      </a>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
                    <div className="col-12">
                      <div className="card-profile-stats d-flex justify-content-center"></div>
                    </div>
                  </Row>
                  <Row className="p-4">
                    <Col lg="6" className={"h3 mt-2"}>
                      <span className={"h4 font-weight-light p-1"}>
                        Email : <a href={"mailto:"}>{getVendorManager.email}</a>
                      </span>
                    </Col>
                    <Col lg="6" className={"h3 mt-2"}>
                      <span className={"h4 font-weight-light p-1"}>
                        Phone : <a href={"tel:"}>{getVendorManager.phone}</a>
                      </span>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* STATION LIST */}
          {getVendorManager.stations.length > 0 && (
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Assgined Station List</h3>
                  </Col>
                  <Col className="text-right" xs="4"></Col>
                </Row>
              </CardHeader>
              <Row>
                <div className="col">
                  <ToolkitProvider
                    data={getVendorManager.stations}
                    keyField="stationName"
                    columns={[
                      {
                        dataField: "stationName",
                        text: "Name",
                        sort: true,
                      },
                      {
                        dataField: "stationAddress",
                        text: "Address",
                        sort: true,
                      },
                      {
                        dataField: "phone",
                        text: "Phone",
                        sort: false,
                      },
                      {
                        dataField: "email",
                        text: "Email",
                        sort: false,
                      },
                    ]}
                    search
                  >
                    {(props) => (
                      <div className="py-4 px-4 table-responsive">
                        <Container fluid>
                          <Row>
                            <Col xs={12} sm={6}>
                              <div
                                id="datatable-basic_filter"
                                className="dataTables_filter pb-1"
                              >
                                <label>
                                  Search:
                                  <SearchBar
                                    className="form-control-sm"
                                    placeholder=""
                                    {...props.searchProps}
                                  />
                                </label>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                        <BootstrapTable
                          ref={componentRef}
                          {...props.baseProps}
                          bootstrap4={true}
                          pagination={pagination}
                          bordered={true}
                          striped
                          hover
                          condensed
                          id="react-bs-table"
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </div>
              </Row>
            </Card>
          )}
          {/* EMPLOYEE LIST */}
          {getVendorManager.engineers.length > 0 && (
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Assigned Engineer List</h3>
                  </Col>
                  <Col className="text-right" xs="4"></Col>
                </Row>
              </CardHeader>
              <Row>
                <div className="col">
                  <ToolkitProvider
                    data={getVendorManager.engineers}
                    keyField="name"
                    columns={[
                      {
                        dataField: "name",
                        text: "Name",
                        sort: true,
                      },
                      {
                        dataField: "phone",
                        text: "Phone",
                        sort: false,
                      },
                      {
                        dataField: "email",
                        text: "Email",
                        sort: false,
                      },
                    ]}
                    search
                  >
                    {(props) => (
                      <div className="py-4 px-4 table-responsive">
                        <Container fluid>
                          <Row>
                            <Col xs={12} sm={6}>
                              <div
                                id="datatable-basic_filter"
                                className="dataTables_filter pb-1"
                              >
                                <label>
                                  Search:
                                  <SearchBar
                                    className="form-control-sm"
                                    placeholder=""
                                    {...props.searchProps}
                                  />
                                </label>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                        <BootstrapTable
                          ref={componentRef}
                          {...props.baseProps}
                          bootstrap4={true}
                          pagination={pagination}
                          bordered={true}
                          striped
                          hover
                          condensed
                          id="react-bs-table"
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </div>
              </Row>
            </Card>
          )}
        </Container>
      )}
    </>
  );
}

export default VendorManagerDetails;
