// list endpoints
export const list_managers = { path: "getAllManagers", method: "get" };
export const list_employees = { path: "getAllEmployees", method: "get" };
export const list_station = { path: "getAllStations", method: "get" };
export const list_client_user = { path: "getAllClientUser", method: "get" };
export const list_jobber = {
  path: "getAllJobber",
  method: "get",
};
export const ota_status = {
  path: "getAllOTAStatus",
  method: "get",
};
export const device_logs = {
  path: "getDeviceLogsByID",
  method: "post",
};
export const device_management_logs = {
  path: "getAllDeviceManagementLogs",
  method: "get",
};
export const device_connection_logs = {
  path: "getAllDeviceConnectionLogs",
  method: "get",
};
export const list_work_order = { path: "getAllWorkOrders", method: "get" };
export const list_inspection = { path: "getAllInspections", method: "get" };
export const list_notOnboarded = {
  path: "getAllStationNotOnboarded",
  method: "get",
};
export const list_every_station = {
  path: "getEveryStation",
  method: "get",
};
export const OTAFile_list = {
  path: "OTAFileList",
  method: "get",
};

// create endpoints
export const create_user = { path: "createUser", method: "post" };
export const create_station = { path: "createStation", method: "post" };
export const create_client_manager = {
  path: "createClientManager",
  method: "post",
};
export const create_client_employee = {
  path: "createClientEmployee",
  method: "post",
};
export const create_manager = { path: "createManager", method: "post" };
export const create_employee = { path: "createEmployee", method: "post" };
export const create_jobber = {
  path: "createJobber",
  method: "post",
};
export const onboard_station = {
  path: "startStationOnboarding",
  method: "post",
};
export const complete_onboarding = { path: "onboardStation", method: "post" };
export const save_draft = { path: "saveDraft", method: "post" };
export const subscription_update = {
  path: "subscriptionUpdate",
  method: "post",
};
export const OTA_update = {
  path: "OTAUpdate",
  method: "post",
};

// check if device id exists
export const check_deviceID = { path: "checkIfDidExists", method: "post" };

// edit and create endpoints
export const edit_owner = {
  path: "updateClientUser",
  method: "post",
};
export const edit_client_manager = {
  path: "updateClientManager",
  method: "post",
};
export const edit_client_employee = {
  path: "updateClientEmployee",
  method: "post",
};
export const edit_station = {
  path: "updateStationById",
  method: "post",
};
export const edit_vendor_manager = {
  path: "updateManager",
  method: "post",
};
export const edit_vendor_engineer = {
  path: "updateEmployee",
  method: "put",
};
export const edit_jobber = {
  path: "updateJobber",
  method: "post",
};
export const create_work_order = {
  path: "createWorkOrder",
  method: "post",
};

// active/inactive accounts endpoints
export const change_station_status = {
  path: "setStationStatus",
  method: "post",
};
export const modify_device = {
  path: "modifyDeviceStatus",
  method: "post",
};
export const change_client_user_status = {
  path: "changeClientUserStatus",
  method: "post",
};
export const change_client_manager_status = {
  path: "changeClientManagerStatus",
  method: "post",
};
export const change_client_employee_status = {
  path: "changeClientEmployeeStatus",
  method: "post",
};
export const change_vendor_manager_status = {
  path: "changeManagerStatus",
  method: "post",
};
export const change_vendor_engineer_status = {
  path: "changeEmployeeStatus",
  method: "post",
};
export const change_jobber_status = {
  path: "changeJobberStatus",
  method: "post",
};

// assign endpoints
export const assign_vendor_manager = {
  path: "assignManagerToStation",
  method: "post",
};
export const assign_vendor_engineer = {
  path: "assignEngineerToManager",
  method: "post",
};
export const assign_jobber = {
  path: "assignJobber",
  method: "post",
};

// unassign endpoints
export const unassign_client_manager_from_station = {
  path: "unassignClientManagerFromStation",
  method: "post",
};

export const unassign_client_employee_from_station = {
  path: "unassignClientEmployeeFromStation",
  method: "post",
};

export const unassign_vendor_engineer_from_manager = {
  path: "unassignVendorEmployeeFromManager",
  method: "post",
};

// view individual endpoints
export const view_owner = { path: "getUserById", method: "post" };
export const view_client_manager = {
  path: "getClientManagerById",
  method: "post",
};
export const view_client_employee = {
  path: "getClientEmployeeById",
  method: "post",
};
export const view_vendor_manager = { path: "getManagerById", method: "get" };
export const view_vendor_engineer = { path: "getEmployeeById", method: "get" };
export const view_jobber = { path: "getJobberById", method: "post" };
export const view_station = { path: "getStationById", method: "post" };
export const view_work_order = { path: "getWorkOrderById", method: "post" };
export const view_inspection = { path: "getInspectionById", method: "post" };

// file endpoints

export const add_file = {
  path: "addFile",
  method: "post",
};
export const all_expired = {
  path: "getAllFilesExpired",
  method: "post",
};
export const all_near_expiry = {
  path: "getAllFilesNearExpiry",
  method: "post",
};
export const all_files = {
  path: "getAllF",
  method: "post",
};
export const near_and_expired = {
  path: "getAllFilesNearAndExpired",
  method: "post",
};
export const near_and_expired_by_id = {
  path: "getAllFilesNearAndExpiredById",
  method: "post",
};
export const del_file = {
  path: "delF",
  method: "post",
};

export const updateConfigStatus = {
  path: "updateConfigStatus",
  method: "post",
};
export const restart_device = {
  path: "restartDeviceAtStation",
  method: "post",
};

export const logout = {
  path: "logout",
  method: "get",
};
