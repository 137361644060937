import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  Row,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { adminRoutes } from "../../../endpoints/base.endpoint";
import * as adminEndpoints from "../../../endpoints/admin.endpoint";

function JobberEdit() {
  const jobber_id = sessionStorage.getItem("jobber_edit_id");
  const [isActiveModal, setisActiveModal] = useState(false);
  const [accountStatus, setAccountStatus] = useState();
  const [NewJobberObject, setNewJobberObject] = useState();
  let newJobberValue = {};

  const config = {
    header: {
      "Access-Control-Allow-Origin": "*",
      authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  };

  let Routes;
  let endpoints;

  Routes = adminRoutes;
  endpoints = adminEndpoints;

  // GET SELECTED JOBBER DETAIL
  const [
    { data: getJobber, loading: getJobberLoading, error: getJobberError },
    refreshJobber,
  ] = Routes({
    url: endpoints.view_jobber.path,
    method: endpoints.view_jobber.method,
    headers: config.header,
    data: {
      jid: jobber_id,
    },
  });

  if (getJobber) {
    newJobberValue = getJobber;
    delete newJobberValue._id;
    delete newJobberValue.password;
  }

  useEffect(() => {
    console.log(NewJobberObject);
  }, [NewJobberObject]);

  const handleEditJO = (typeofObj, value) => {
    newJobberValue[typeofObj] = value;
    setNewJobberObject((NewJobberObject) => ({
      ...NewJobberObject,
      ...newJobberValue,
    }));
    console.log(NewJobberObject);
  };

  // UPDATE JOBBER DETAIL API
  const [
    { loading: updateJobberLoading, error: updateJobberError },
    updateJobber,
  ] = adminRoutes(
    {
      url: adminEndpoints.edit_jobber.path,
      method: adminEndpoints.edit_jobber.method,
      headers: config.header,
      data: { jid: jobber_id, ...NewJobberObject },
    },
    { manual: true }
  );

  // ACCOUNT STATUS UPDATE API
  const [{ loading, error }, changeStatus] = adminRoutes(
    {
      url: adminEndpoints.change_jobber_status.path,
      method: adminEndpoints.change_jobber_status.method,
      headers: config.header,
      data: {
        id: jobber_id,
        status: accountStatus,
      },
    },
    { manual: true }
  );

  // HANDLE SUBMIT BUTTON
  function handleNewJobberSubmit() {
    updateJobber()
      .then((res) => {
        if (res.status === 200) {
          console.log(res);
          window.location.href = "/admin/jobber-list";
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //TOOGLE MODAL
  function closeModal() {
    if (isActiveModal) {
      setisActiveModal(false);
    } else {
      setisActiveModal(true);
    }
  }

  // HANDLE ACCOUNT ACITVE SUBMIT
  function handleStatusSubmit() {
    console.log("account status", accountStatus);
    closeModal();
    changeStatus()
      .then((res) => {
        console.log("status", res);
        refreshJobber();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <SimpleHeader name="Jobber Edit" parentName="Jobber" />
      <Modal
        className="modal-dialog-centered w-100 h-100"
        backdrop={true}
        size="md"
        centered={true}
        scrollable={true}
        isOpen={isActiveModal}
        toggle={closeModal}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Active/Deactive Account
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={closeModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <div>
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <div
                      className="mb-2"
                      style={{ fontSize: "0.875rem", fontWeight: "600" }}
                    >
                      Is Active?
                    </div>
                    <label className="custom-toggle">
                      <input
                        defaultChecked={getJobber && getJobber.isActive}
                        type="checkbox"
                        onChange={(e) => setAccountStatus(e.target.checked)}
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </label>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
        <div className="modal-footer">
          <Button color="danger" onClick={handleStatusSubmit}>
            Submit
          </Button>{" "}
          <Button onClick={closeModal}>Cancel</Button>
        </div>
      </Modal>
      <Container className="mt--6" fluid>
        {getJobber && (
          <Card>
            {/* <CardHeader className="bg-transparent d-flex align-items-center justify-content-between">
              <h3 className="mb-0">Jobber Edit</h3>
              <button className="btn btn-warning btn-sm" onClick={closeModal}>
                Status
              </button>
            </CardHeader> */}
            <CardBody>
              <Form>
                <div className="bg-transparent d-flex align-items-center justify-content-between">
                  <a href="./jobber-list" className="text-white mb-3">
                    <Button size="sm" color="default" type="button">
                      Back
                    </Button>
                  </a>
                  <button
                    className="btn btn-warning btn-sm"
                    onClick={closeModal}
                    type="button"
                  >
                    Status
                  </button>
                </div>
                <h6 className="heading-small text-muted mb-4">
                  Jobber Information
                </h6>
                <div className="px-lg-4">
                  <Row>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-jobber-name"
                        >
                          Jobber Name
                        </label>
                        <Input
                          id="input-jobber-name"
                          type={"text"}
                          placeholder={"Jobber Name"}
                          defaultValue={getJobber.name}
                          onChange={(e) => {
                            handleEditJO("name", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-jobber-email"
                        >
                          Jobber Email
                        </label>
                        <Input
                          id="input-jobber-email"
                          type={"text"}
                          placeholder={"Jobber Email"}
                          defaultValue={getJobber.email}
                          onChange={(e) => {
                            handleEditJO("email", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-jobber-phone"
                        >
                          Jobber Phone
                        </label>
                        <Input
                          id="input-jobber-phone"
                          type={"tel"}
                          placeholder={"Jobber phone"}
                          defaultValue={getJobber.phone}
                          onChange={(e) => {
                            handleEditJO("phone", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-company-name"
                        >
                          Company Name
                        </label>
                        <Input
                          id="input-company-name"
                          type={"text"}
                          placeholder={"Company Name"}
                          defaultValue={getJobber.companyName}
                          onChange={(e) => {
                            handleEditJO("companyName", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-company-address"
                        >
                          Company Address
                        </label>
                        <Input
                          id="input-company-address"
                          type={"text"}
                          placeholder={"Company Address"}
                          defaultValue={getJobber.companyAddress}
                          onChange={(e) => {
                            handleEditJO("companyAddress", e.target.value);
                          }}
                          maxLength={"50"}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="company-city"
                        >
                          Company City
                        </label>
                        <Input
                          id="company-city"
                          rows="6"
                          type={"text"}
                          placeholder={"Company City"}
                          defaultValue={getJobber.companyCity}
                          onChange={(e) => {
                            handleEditJO("companyCity", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="company-state"
                        >
                          Company State
                        </label>
                        <Input
                          id="company-state"
                          rows="6"
                          type={"text"}
                          placeholder={"Company State"}
                          defaultValue={getJobber.companyState}
                          onChange={(e) => {
                            handleEditJO("companyState", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="company-country"
                        >
                          Company Country
                        </label>
                        <Input
                          id="company-country"
                          rows="6"
                          type={"text"}
                          placeholder={"Company Country"}
                          defaultValue={getJobber.companyCountry}
                          onChange={(e) => {
                            handleEditJO("companyCountry", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="company-zip"
                        >
                          Company Zip
                        </label>
                        <Input
                          id="company-zip"
                          rows="6"
                          type={"text"}
                          placeholder={"Company Zip"}
                          defaultValue={getJobber.companyZip}
                          onChange={(e) => {
                            handleEditJO("companyZip", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Form>
            </CardBody>
            <CardFooter>
              <Button color="default" onClick={handleNewJobberSubmit}>
                Update Jobber
              </Button>
              <a className="btn btn-danger" href="./jobber-list">
                Cancel
              </a>
            </CardFooter>
          </Card>
        )}
      </Container>
    </>
  );
}

export default JobberEdit;
