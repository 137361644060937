import React, { useEffect, useState } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import SimpleHeader from "components/Headers/SimpleHeader";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Col,
  Collapse,
  Container,
  FormGroup,
  Row,
  Input,
} from "reactstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faTimes,
  faChevronDown,
  faChevronUp,
  faMapMarkedAlt,
} from "@fortawesome/free-solid-svg-icons";
import { adminRoutes } from "../../../endpoints/base.endpoint";
import * as adminEndpoints from "../../../endpoints/admin.endpoint";
import { socket_con } from "../../../clients/socket";
import { Base64 } from "js-base64";

function CompleteOnboarding() {
  const [allStations, setAllStations] = useState([]);
  const [isDeviceGatewayChecked, setIsDeviceGatewayChecked] = useState(false);
  // Use an array of booleans to manage the collapse state for each card
  const [cardCollapseStates, setCardCollapseStates] = useState([]);
  const [command, setCommand] = useState("I20100");
  const _id = sessionStorage.getItem("onboarding_id");

  const toggle = (index) => {
    // Toggle the collapse state for the clicked card
    setCardCollapseStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      return newStates;
    });
  };

  const config = {
    header: {
      "Access-Control-Allow-Origin": "*",
      authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  };

  // GET ALL STATION API
  const [{ data, loading, error }] = adminRoutes({
    url: adminEndpoints.list_every_station.path,
    method: adminEndpoints.list_every_station.method,
    headers: config.header,
  });

  useEffect(() => {
    if (data) {
      setAllStations(data);
    }
  }, [data]);

  // GET SELECTED STATION'S DETAILS
  const [
    { data: getStation, loading: getStationLoading, error: getStationError },
  ] = adminRoutes({
    url: adminEndpoints.view_station.path,
    method: adminEndpoints.view_station.method,
    headers: config.header,
    data: { id: _id },
  });

  // ONBOARDING API
  const [
    { loading: stationOnboardingLoading, error: stationOnboardingError },
    completeStationOnboarding,
  ] = adminRoutes(
    {
      url: adminEndpoints.complete_onboarding.path,
      method: adminEndpoints.complete_onboarding.method,
      headers: config.header,
    },
    { manual: true }
  );

  const [{ loading: saveDraftLoading, error: saveDraftApiError }, saveToDraft] =
    adminRoutes(
      {
        url: adminEndpoints.save_draft.path,
        method: adminEndpoints.save_draft.method,
        headers: config.header,
      },
      { manual: true }
    );

  const {
    control,
    setValue,
    register,
    handleSubmit,
    setError,
    getValues,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      shift: [
        {
          status: false,
          time: "00:00",
        },
        {
          status: false,
          time: "00:00",
        },
        {
          status: false,
          time: "00:00",
        },
        {
          status: false,
          time: "00:00",
        },
      ],
    },
  });

  useEffect(() => {
    if (getStation) {
      setValue("stationName", getStation.stationName || "");
      setValue("stationAddress", getStation.stationAddress || "");
      setValue("city", getStation.city || "");
      setValue("state", getStation.state || "");
      setValue("zip", getStation.zip || "");
      setValue("email", getStation.email || "");
      setValue("phone", getStation.phone || "");
      setValue("stationContact", getStation.stationContact || "");
      setValue("ATGModel", getStation.ATGModel || "");
      setValue("gateway", getStation.gateway || "");
      setValue("deviceID", getStation.deviceID || "");
      setValue("facilityId", getStation.facilityId || "");
      setValue("ownerId", getStation.ownerId || "");
      setValue("ATGtype", getStation.ATGtype || "");
      setValue("consoleType", getStation.consoleType || "");
      setValue("dispenserType", getStation.dispenserType || "");
      setValue("latitude", getStation?.location?.coordinates[1] || "");
      setValue("longitude", getStation?.location?.coordinates[0] || "");
      setValue("placeID", getStation.placeID || "");
      if (getStation.tank) {
        setValue(
          "tank",
          Object.values(getStation.tank) || {
            capacity: "",
            type: "",
            color: "",
            minRatio: 20,
          }
        );
      }
      if (getStation.shift) {
        setValue(
          "shift",
          Object.values(getStation.shift) || {
            status: false,
            time: "00:00",
          }
        );
      }
    }
  }, [getStation]);

  // react-hook-form fieldArray to add/remove dynamic tank as need
  const { fields, append, remove } = useFieldArray({
    name: "tank",
    control,
  });

  const { fields: shiftFields } = useFieldArray({
    name: "shift",
    control,
  });

  // this function remove empty key from object
  function removeEmptyFields(data) {
    Object.keys(data).forEach((key) => {
      if (data[key] === "" || data[key] == null) {
        delete data[key];
      }
    });
  }

  const onSubmit = async (data, action) => {
    console.log(data);
    // function to remove not required empty input fields
    removeEmptyFields(data);

    if (!getStation.deviceID) {
      // check if entered deviceID already exists
      let duplicateDeviceId;
      if (data.deviceID) {
        duplicateDeviceId = allStations.some(
          (station) => station.deviceID === data.deviceID
        );
      }

      if (duplicateDeviceId) {
        setError("deviceID", {
          type: "manual",
          message: "Device ID already exists",
        });
        return;
      }
    }

    // this transformed array of tank in tank object
    const transformedTank = data.tank.reduce((acc, tank, index) => {
      acc[(index + 1).toString().padStart(2, "0")] = tank;
      return acc;
    }, {});

    const newData = { ...data };
    newData.tank = transformedTank;
    newData._id = _id;

    // this transformed array of shift in shift object
    const transformedShift = data.shift.reduce((acc, shift, index) => {
      acc[(index + 1).toString().padStart(2, "0")] = shift;
      return acc;
    }, {});

    newData.shift = transformedShift;

    try {
      if (action === "create") {
        newData.isDraft = false;

        // Make the completeOnboardingStationRequest API call
        const completeOnboardingStationResponse =
          await completeStationOnboarding({
            data: newData,
          });
        if (completeOnboardingStationResponse) {
          window.location.href = "/admin/station-list";
        }
      } else if (action === "saveToDraft") {
        console.log(data);
        console.log("save to draft triggered");
        // Only perform validation for "Station Name" when saving to draft
        if (!data.stationName) {
          setError("stationName", {
            type: "manual",
            message: "Station name is required",
          });
          return;
        }

        newData.isDraft = true;
        // Make the saveToDraft API call
        const saveToDraftResponse = await saveToDraft({
          data: newData,
        });
        if (saveToDraftResponse) {
          window.location.href = "/admin/onboarding-queue";
        }
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const handleDeviceGatewayChange = (e) => {
    setIsDeviceGatewayChecked(e.target.checked);
  };

  // tank and tank colors select options
  const regular = [
    {
      label: "Regular (Charlotte)",
      value: {
        fuelType: "Regular",
        color: { label: "Charlotte", value: "#B9FBF3" },
      },
    },
    {
      label: "Regular (Turquoise Blue)",
      value: {
        fuelType: "Regular",
        color: { label: "Turquoise Blue", value: "#51F4E1" },
      },
    },
    {
      label: "Regular (Keppel)",
      value: {
        fuelType: "Regular",
        color: { label: "Keppel", value: "#39AB9E" },
      },
    },
  ];

  const sup = [
    {
      label: "Super (Rose Bud)",
      value: {
        fuelType: "Super",
        color: { label: "Rose Bud", value: "#F8B1A2" },
      },
    },
    {
      label: "Super (Flamingo)",
      value: {
        fuelType: "Super",
        color: { label: "Flamingo", value: "#F06244" },
      },
    },
    {
      label: "Super (Mojo)",
      value: {
        fuelType: "Super",
        color: { label: "Mojo", value: "#C04E36" },
      },
    },
  ];

  const diesel = [
    {
      label: "Diesel (Vista Blue)",
      value: {
        fuelType: "Diesel",
        color: { label: "Vista Blue", value: "#9BDAC2" },
      },
    },
    {
      label: "Diesel (Keppel)",
      value: {
        fuelType: "Diesel",
        color: { label: "Keppel", value: "#36B485" },
      },
    },
    {
      label: "Diesel (Eucalyptus)",
      value: {
        fuelType: "Diesel",
        color: { label: "Eucalyptus", value: "#267E5D" },
      },
    },
  ];

  const truckDSL = [
    {
      label: "Truck DSL (Banana Mania)",
      value: {
        fuelType: "Truck DSL",
        color: { label: "Banana Mania", value: "#FCE5B5" },
      },
    },
    {
      label: "Truck DSL (Casablanca)",
      value: {
        fuelType: "Truck DSL",
        color: { label: "Casablanca", value: "#F7BF47" },
      },
    },
    {
      label: "Truck DSL (Luxor Gold)",
      value: {
        fuelType: "Truck DSL",
        color: { label: "Luxor Gold", value: "#AD8632" },
      },
    },
  ];

  const DEF = [
    {
      label: "DEF (Perano)",
      value: {
        fuelType: "DEF",
        color: { label: "Perano", value: "#A8CAF1" },
      },
    },
    {
      label: "DEF (Mariner)",
      value: {
        fuelType: "DEF",
        color: { label: "Mariner", value: "#267ADD" },
      },
    },
    {
      label: "DEF (Fun Blue)",
      value: {
        fuelType: "DEF",
        color: { label: "Fun Blue", value: "#1B559D" },
      },
    },
  ];

  const tankProductsGroup = [
    { label: "Regular", options: regular },
    { label: "Super", options: sup },
    { label: "Diesel", options: diesel },
    { label: "Truck DSL", options: truckDSL },
    { label: "DEF", options: DEF },
  ];

  //   this function return index of tankProductsGroup and index of option
  function findColorIndexInTankProductsGroup(colorValue) {
    for (
      let groupIndex = 0;
      groupIndex < tankProductsGroup.length;
      groupIndex++
    ) {
      const optionIndex = tankProductsGroup[groupIndex].options.findIndex(
        (option) => option.value.color.value === colorValue
      );
      if (optionIndex !== -1) {
        return { groupIndex, optionIndex };
      }
    }

    // If the color is not found in any group
    return null;
  }

  // tank color select style
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.data.value.color.value,
      fontWeight: 500,
      // color: state.data.value,
      color: "black",
    }),
  };

  console.log(errors);

  // TODO : make api call to get details
  function handleCmdFormSubmit() {
    generateReport();
    console.log(command);
  }

  //SOCKET IMPLEMENTATION

  const [report, setReport] = useState(null);
  const [pos, setPos] = useState("00");
  const [loading0, setLoading] = useState(false);
  const deviceId = getStation ? getStation.deviceID : "";
  const [socket, setSocket] = useState(socket_con);
  // let socket = io.connect("http://159.223.152.51:5111");

  //TODO change the topic
  const gen_topic = "toc/" + deviceId + "/generate";
  let msg = "I20100";

  socket.on("connect", function () {
    console.log("connected to socket.io");
  });
  //TODO change the topic
  socket.on("generated", function (msg) {
    console.log(Base64.decode(msg.payload));
    setReport(Base64.decode(msg.payload));
    setLoading(false);
  });
  function generateReport() {
    //TODO change the topic
    socket.emit("generate", {
      topic: gen_topic,
      payload: msg,
    });
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 20000);
  }
  useEffect(() => {
    if (report) {
      document.getElementById("report").innerHTML = report;
    }
  }, [report]);
  useEffect(() => {
    // console.log(command);
    msg = command;
    // console.log(msg);
  }, [command]);

  return (
    <>
      <SimpleHeader name="Complete Onboarding" parentName="Station" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="9">
            <Card>
              <CardHeader className="bg-transparent">
                <h3 className="mb-0 ">Complete Onboarding</h3>
              </CardHeader>
              <CardBody>
                <h6 className="heading-small text-muted mb-4">
                  Station Basic Information
                </h6>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="4">
                        <label
                          htmlFor="stationName"
                          className="form-control-label"
                        >
                          Station Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Station Name"
                          {...register("stationName", {
                            required: "Station name is required",
                          })}
                          disabled
                        />
                        {
                          <span className="text-danger">
                            {errors.stationName?.message}
                          </span>
                        }
                      </Col>
                      <Col lg="6">
                        <label
                          htmlFor="stationAddress"
                          className="form-control-label"
                        >
                          Station Address
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Station Address"
                          {...register("stationAddress", {
                            required: "Station address is required.",
                          })}
                        />
                        {
                          <span className="text-danger">
                            {errors.stationAddress?.message}
                          </span>
                        }
                      </Col>
                      <Col lg="2">
                        <label htmlFor="city" className="form-control-label">
                          City
                        </label>
                        <input
                          type="text"
                          placeholder="City"
                          className="form-control"
                          {...register("city", {
                            required: "City is required.",
                          })}
                        />
                        {
                          <span className="text-danger">
                            {errors.city?.message}
                          </span>
                        }
                      </Col>
                      <Col lg="3">
                        <label htmlFor="state" className="form-control-label">
                          State
                        </label>
                        <input
                          type="text"
                          placeholder="State"
                          className="form-control"
                          {...register("state", {
                            required: "State is required.",
                          })}
                        />
                        {
                          <span className="text-danger">
                            {errors.state?.message}
                          </span>
                        }
                      </Col>
                      <Col lg="3">
                        <label htmlFor="zip" className="form-control-label">
                          Zip
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Zip"
                          {...register("zip", {
                            required: "Zip is required.",
                          })}
                        />
                        {
                          <span className="text-danger">
                            {errors.zip?.message}
                          </span>
                        }
                      </Col>
                      <Col lg="3">
                        <label htmlFor="email" className="form-control-label">
                          Email
                        </label>
                        <input
                          type="email"
                          placeholder="Email"
                          className="form-control"
                          {...register("email", {
                            required: "Email is required.",
                          })}
                        />
                        {
                          <span className="text-danger">
                            {errors.email?.message}
                          </span>
                        }
                      </Col>
                      <Col lg="3">
                        <label htmlFor="phone" className="form-control-label">
                          Phone
                        </label>
                        <input
                          type="text"
                          placeholder="Phone"
                          className="form-control"
                          {...register("phone", {
                            required: "Phone is required.",
                          })}
                        />
                        {
                          <span className="text-danger">
                            {errors.phone?.message}
                          </span>
                        }
                      </Col>
                      <Col lg="3">
                        <label
                          htmlFor="stationContact"
                          className="form-control-label"
                        >
                          Station Contact Person
                        </label>
                        <input
                          type="text"
                          placeholder="Station Contact Person"
                          className="form-control"
                          {...register("stationContact", {
                            required: "Station contact Person is required.",
                          })}
                        />
                        {
                          <span className="text-danger">
                            {errors.stationContact?.message}
                          </span>
                        }
                      </Col>
                      <Col lg="3">
                        <label
                          htmlFor="ATG Model"
                          className="form-control-label"
                        >
                          ATG Model
                        </label>
                        <select
                          {...register("ATGModel")}
                          className="form-control"
                          value={getValues("ATGModel")}
                          disabled
                        >
                          <option value="tls350s">
                            VEEDER-ROOT TLS-350 SERIAL
                          </option>
                          <option value="tls450s">
                            VEEDER-ROOT TLS-450 SERIAL
                          </option>
                        </select>
                      </Col>
                      <Col lg="3">
                        <Controller
                          name="gateway"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <div className="d-flex flex-column justify-content-end">
                              <div
                                className="mb-2 align-self-lg-end"
                                style={{
                                  fontSize: "0.875rem",
                                  fontWeight: "600",
                                }}
                              >
                                Device Gateway
                              </div>
                              <div className="align-self-lg-end">
                                <label className="custom-toggle">
                                  <input
                                    type="checkbox"
                                    defaultChecked={value}
                                    onChange={(e) => {
                                      onChange(e);
                                      handleDeviceGatewayChange(e);
                                    }}
                                  />
                                  <span className="custom-toggle-slider rounded-circle " />
                                </label>
                              </div>
                            </div>
                          )}
                        />
                      </Col>
                      {(isDeviceGatewayChecked ||
                        (getStation && getStation?.gateway)) && (
                        <Col lg="3">
                          <label
                            htmlFor="deviceId"
                            className="form-control-label"
                          >
                            Device Identifier
                          </label>
                          <input
                            type="text"
                            placeholder="Device Identifier"
                            disabled={getStation && getStation?.gateway}
                            {...register("deviceID", {
                              maxLength: {
                                value: 12,
                                message:
                                  "Device ID must be exactly 12 characters long.",
                              },
                              minLength: {
                                value: 12,
                                message:
                                  "Device ID must be exactly 12 characters long.",
                              },
                              required: isDeviceGatewayChecked
                                ? "Device ID is required."
                                : false,
                              pattern: {
                                value: /^[A-Z0-9]{12}$/,
                                message: "Only uppercase letters and numbers.",
                              },
                            })}
                            className="form-control"
                          />
                          {isDeviceGatewayChecked && (
                            <span className="text-danger">
                              {errors.deviceID?.message}
                            </span>
                          )}
                        </Col>
                      )}
                      <Col lg="3">
                        <label
                          htmlFor="latitude"
                          className="form-control-label"
                        >
                          Latitude <span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          min={-90}
                          max={90}
                          step={0.0000001}
                          placeholder="Latitude"
                          className="form-control"
                          {...register("latitude", {
                            required: "Latitude is required.",
                          })}
                        />
                        {
                          <span className="text-danger">
                            {errors?.location?.coordinates[0]?.message}
                          </span>
                        }
                      </Col>
                      <Col lg="3">
                        <label
                          htmlFor="longitude"
                          className="form-control-label"
                        >
                          Longitude <span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          min={-180}
                          max={180}
                          step={0.0000001}
                          placeholder="Longitude"
                          className="form-control"
                          {...register("longitude", {
                            required: "Longitude is required.",
                          })}
                        />
                        {
                          <span className="text-danger">
                            {errors.stationContact?.message}
                          </span>
                        }
                      </Col>
                      <Col lg="3">
                        <label htmlFor="placeID" className="form-control-label">
                          Place ID <span className="text-danger">*</span>
                        </label>

                        <div className="d-flex justify-content-center align-items-center">
                          <input
                            type="text"
                            placeholder="Place Id"
                            className="form-control"
                            {...register("placeID", {
                              required: "Place Id is required.",
                            })}
                          />
                          <a
                            className="ml-1 "
                            href={`https://developers.google.com/maps/documentation/geocoding/overview#how-the-geocoding-api-works`}
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Get Place ID from Google Maps"
                          >
                            <FontAwesomeIcon icon={faMapMarkedAlt} size="2x" />
                          </a>
                        </div>

                        {
                          <span className="text-danger">
                            {errors.stationContact?.message}
                          </span>
                        }
                      </Col>
                    </Row>
                  </div>
                  <hr />
                  <h6 className="heading-small text-muted mb-4">
                    Station Additional Information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="3">
                        <label
                          className="form-control-label"
                          htmlFor="facilityId"
                        >
                          Facility ID Number
                        </label>
                        <input
                          type="text"
                          placeholder="Facility ID Number"
                          className="form-control"
                          {...register("facilityId")}
                        />
                      </Col>
                      <Col lg="3">
                        <label
                          className="form-control-label"
                          htmlFor="input-ownerId"
                        >
                          Owner ID Number
                        </label>
                        <input
                          type="text"
                          placeholder="Owner ID Number"
                          className="form-control"
                          {...register("ownerId")}
                        />
                      </Col>
                      <Col lg="2">
                        <label className="form-control-label" htmlFor="ATGtype">
                          ATG Type
                        </label>
                        <input
                          type="text"
                          placeholder="ATG Type"
                          className="form-control"
                          {...register("ATGtype", {
                            required: "ATG Type is required.",
                          })}
                        />
                        {
                          <span className="text-danger">
                            {errors.ATGtype?.message}
                          </span>
                        }
                      </Col>
                      <Col lg="2">
                        <label
                          className="form-control-label"
                          htmlFor="consoleType"
                        >
                          Console Type
                        </label>
                        <input
                          type="text"
                          placeholder="Console Type"
                          className="form-control"
                          {...register("consoleType")}
                        />
                      </Col>
                      <Col lg="2">
                        <label
                          className="form-control-label"
                          htmlFor="dispenserType"
                        >
                          Dispenser Type
                        </label>
                        <input
                          type="text"
                          placeholder="Dispenser Type"
                          className="form-control"
                          {...register("dispenserType")}
                        />
                      </Col>
                    </Row>
                  </div>
                  <hr />
                  <h6 className="heading-small text-muted mb-4">
                    SHIFT INFORMATION
                  </h6>
                  <div className="px-lg-4">
                    <Row>
                      {shiftFields.map((field, index) => (
                        <Col lg="3" key={field.id}>
                          <Card>
                            <CardHeader>
                              <div className="d-flex justify-content-between align-items-center">
                                <label
                                  htmlFor={`shift${index}`}
                                  className="form-control-label p-0 m-0"
                                >
                                  Shift #{`${index + 1}`}
                                </label>
                                <input
                                  type="checkbox"
                                  name={`shift[${index}].status`}
                                  {...register(`shift.${index}.status`)}
                                />
                              </div>
                            </CardHeader>
                            <CardBody>
                              <input
                                type="time"
                                className="form-control"
                                name={`shift.${index}.time`}
                                {...register(`shift[${index}].time`)}
                              />
                            </CardBody>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </div>
                  <hr />
                  <h6 className="heading-small text-muted mb-4">
                    TANK INFORMATION
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      {fields.map((field, index) => {
                        const foundIndex = findColorIndexInTankProductsGroup(
                          field.color
                        );

                        return (
                          <Col lg="4" key={field.id}>
                            <Card>
                              <CardHeader>
                                <div className="d-flex justify-content-between">
                                  <label
                                    className="form-control-label"
                                    htmlFor={`tank${index}`}
                                  >
                                    {`Tank #${index + 1}`}
                                  </label>
                                  {index > 0 && (
                                    <FontAwesomeIcon
                                      icon={faTimes}
                                      size="xl"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => remove(index)}
                                      color="red"
                                    />
                                  )}
                                </div>
                              </CardHeader>
                              <CardBody>
                                <Controller
                                  name={`tank[${index}].type`}
                                  control={control}
                                  defaultValue=""
                                  rules={{
                                    validate: (value) =>
                                      value !== "" || "Tank type is required",
                                  }}
                                  render={({ field }) => (
                                    <>
                                      <Select
                                        name={`tank[${index}.type]`}
                                        placeholder={"Product Type"}
                                        options={tankProductsGroup}
                                        styles={customStyles}
                                        isClearable={true}
                                        defaultValue={
                                          foundIndex &&
                                          tankProductsGroup[
                                            foundIndex.groupIndex
                                          ].options[foundIndex.optionIndex]
                                        }
                                        value={tankProductsGroup.find(
                                          (option) =>
                                            option.value?.fuelType ===
                                            field.value
                                        )}
                                        onChange={(selectedOption) => {
                                          field.onChange(
                                            selectedOption
                                              ? selectedOption.value.fuelType
                                              : ""
                                          );
                                          // Set the color in the form data if an option is selected
                                          if (selectedOption) {
                                            const colorField = `tank[${index}].color`;
                                            setValue(
                                              colorField,
                                              selectedOption.value.color.value
                                            );
                                          }
                                        }}
                                      />
                                      {errors.tank &&
                                        errors.tank[index] &&
                                        errors.tank[index].type && (
                                          <span className="text-danger">
                                            {errors.tank[index].type.message}
                                          </span>
                                        )}
                                    </>
                                  )}
                                />
                                <label
                                  htmlFor={`tank.${index}.capacity`}
                                  className="form-control-label"
                                ></label>
                                <input
                                  type="text"
                                  placeholder="Tank Capacity"
                                  className="form-control"
                                  {...register(`tank.${index}.capacity`, {
                                    required: "Tank capacity is required.",
                                  })}
                                />
                                {errors.tank &&
                                  errors.tank[index] &&
                                  errors.tank[index].capacity && (
                                    <span className="text-danger">
                                      {errors.tank[index].capacity.message}
                                    </span>
                                  )}
                                <Collapse isOpen={cardCollapseStates[index]}>
                                  <label
                                    htmlFor={`tank.${index}.minRatio`}
                                  ></label>
                                  <input
                                    type="number"
                                    placeholder="Minimum Ratio"
                                    className="form-control"
                                    {...register(`tank.${index}.minRatio`)}
                                  />
                                  <label
                                    htmlFor={`tank.${index}.min_del`}
                                  ></label>
                                  <input
                                    type="number"
                                    placeholder="Minimum Delivery"
                                    className="form-control"
                                    {...register(`tank.${index}.min_del`)}
                                  />
                                  <label
                                    htmlFor={`tank.${index}.max_del`}
                                  ></label>
                                  <input
                                    type="number"
                                    placeholder="Maximum Delivery"
                                    className="form-control"
                                    {...register(`tank.${index}.max_del`)}
                                  />
                                </Collapse>
                                <div
                                  className="text-center"
                                  style={{ paddingTop: "1rem" }}
                                >
                                  <FontAwesomeIcon
                                    icon={
                                      cardCollapseStates[index]
                                        ? faChevronUp
                                        : faChevronDown
                                    }
                                    size="xl"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => toggle(index)}
                                    color="#172b4d"
                                  />
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        );
                      })}
                      <Col lg="4">
                        <Card
                          style={{ height: "266px", cursor: "pointer" }}
                          onClick={() => append({ minRatio: 20 })}
                        >
                          <CardBody className="d-flex flex-column justify-content-center align-items-center">
                            <FontAwesomeIcon icon={faPlus} size="2x" />
                            Add Tank
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                  <Button
                    type="submit"
                    color="default"
                    disabled={stationOnboardingLoading || !isValid}
                    onClick={() => {
                      console.log(getValues);
                      handleSubmit((data) => onSubmit(data, "create"))();
                    }}
                  >
                    {stationOnboardingLoading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Submitting...</span>
                      </>
                    ) : (
                      "Complete Onboarding"
                    )}
                  </Button>
                  <Button
                    type="button"
                    color="default"
                    disabled={saveDraftLoading}
                    onClick={async () => {
                      // Trigger validation for the 'stationName' field
                      const isStationNameValid = await trigger("stationName");

                      if (isStationNameValid) {
                        onSubmit(getValues(), "saveToDraft");
                      }
                    }}
                  >
                    {saveDraftLoading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Saving...</span>
                      </>
                    ) : (
                      "Save to Draft"
                    )}{" "}
                  </Button>
                </form>
              </CardBody>
            </Card>
          </Col>
          {/* command form */}
          <Col lg="3">
            <Card>
              <CardHeader className="bg-transparent">
                <h3 className="mb-0 ">Get Details From Tank Controller</h3>
              </CardHeader>
              <CardBody>
                <h6 className="heading-small text-muted mb-4">
                  Station Information
                </h6>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="commandList"
                        >
                          Information Type
                        </label>
                        <Input
                          type="select"
                          id="commandList"
                          rows="6"
                          value={command}
                          onChange={(e) => setCommand(e.target.value)}
                        >
                          <option value="I20100">Station Header Details</option>
                          <option value="I62800">Tank Size</option>
                          <option value="I50201">First Shift Time</option>
                          <option value="I50202">Second Shift Time</option>
                          <option value="I50203">Third Shift Time</option>
                          <option value="I50204">Fourth Shift Time</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </CardBody>
              <CardFooter>
                <Button color="default" onClick={handleCmdFormSubmit}>
                  Get Detail
                </Button>
              </CardFooter>
            </Card>
            <Card className={"mt-2"}>
              <CardBody>
                <pre className="p-4" id={"report"}></pre>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CompleteOnboarding;
