import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  Row,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { useEffect } from "react";
import { useState } from "react";
import { adminRoutes } from "../../../endpoints/base.endpoint";
import * as adminEndpoints from "../../../endpoints/admin.endpoint";

function VendorEngineerEdit() {
  const emp_id = sessionStorage.getItem("vendor_engineer_edit_id");
  const [isActiveModal, setisActiveModal] = useState(false);
  const [accountStatus, setAccountStatus] = useState();
  const [NewEmployeeObject, setNewEmployeeObject] = useState({
    id: emp_id,
    name: "",
    email: "",
    phone: "",
    isActive: true,
  });

  let newEmployeeValue = {
    id: emp_id,
  };

  useEffect(() => {
    console.log(NewEmployeeObject);
  }, [NewEmployeeObject]);

  const handleEditEO = (typeofObj, value) => {
    newEmployeeValue[typeofObj] = value;
    setNewEmployeeObject((NewEmployeeObject) => ({
      ...NewEmployeeObject,
      ...newEmployeeValue,
    }));
    console.log(NewEmployeeObject);
  };

  const config = {
    header: {
      "Access-Control-Allow-Origin": "*",
      authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  };

  let Routes;
  let endpoints;
  Routes = adminRoutes;
  endpoints = adminEndpoints;

  const [
    {
      data: getVendorEmployee,
      loading: getVendorEmployeeLoading,
      error: getVendorEmployeeError,
    },
    refreshEmployee,
  ] = Routes({
    url: endpoints.view_vendor_engineer.path + "/" + emp_id,
    method: endpoints.view_vendor_engineer.method,
    headers: config.header,
  });

  useEffect(() => {
    if (getVendorEmployee) {
      setNewEmployeeObject({
        name: getVendorEmployee?.emp?.name,
        email: getVendorEmployee?.emp?.email,
        phone: getVendorEmployee?.emp?.phone,
        isActive: getVendorEmployee?.emp?.isActive,
      });
    }
  }, [getVendorEmployee]);

  // update button api call
  const [
    { loading: getVendorEngineerLoading, error: getVendorEngineerError },
    updateVendorEngineer,
  ] = Routes(
    {
      url: endpoints.edit_vendor_engineer.path + "/" + emp_id,
      method: endpoints.edit_vendor_engineer.method,
      headers: config.header,
      data: NewEmployeeObject,
    },
    { manual: true }
  );

  // ACCOUNT STATUS UPDATE API
  const [{ loading, error }, changeStatus] = adminRoutes(
    {
      url: adminEndpoints.change_vendor_engineer_status.path,
      method: adminEndpoints.change_vendor_engineer_status.method,
      headers: config.header,
      data: {
        id: emp_id,
        status: accountStatus,
      },
    },
    { manual: true }
  );

  function handleUpdateVendorEngineerSubmit() {
    updateVendorEngineer()
      .then((res) => {
        window.location.href = "/admin/employee-list";
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //TOOGLE MODAL
  function closeModal() {
    if (isActiveModal) {
      setisActiveModal(false);
    } else {
      setisActiveModal(true);
    }
  }

  // HANDLE ACCOUNT ACITVE SUBMIT
  function handleUnassignSubmit() {
    console.log("account status", accountStatus);
    closeModal();
    changeStatus()
      .then((res) => {
        console.log("status", res);
        refreshEmployee();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <SimpleHeader name="Vendor Engineer Edit" parentName="Vendor" />
      <Modal
        className="modal-dialog-centered w-100 h-100"
        backdrop={true}
        size="md"
        centered={true}
        scrollable={true}
        isOpen={isActiveModal}
        toggle={closeModal}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Active/Deactive Account
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={closeModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <div>
              <Row>
                <Col lg="4">
                  <FormGroup>
                    <div
                      className="mb-2"
                      style={{ fontSize: "0.875rem", fontWeight: "600" }}
                    >
                      Is Active?
                    </div>
                    <label className="custom-toggle">
                      <input
                        defaultChecked={
                          getVendorEmployee && getVendorEmployee?.emp.isActive
                        }
                        type="checkbox"
                        onChange={(e) => setAccountStatus(e.target.checked)}
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </label>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
        <div className="modal-footer">
          <Button color="danger" onClick={handleUnassignSubmit}>
            Submit
          </Button>{" "}
          <Button onClick={closeModal}>Cancel</Button>
        </div>
      </Modal>
      {getVendorEmployee && (
        <Container className="mt--6" fluid>
          <Card>
            {/* <CardHeader className="bg-transparent d-flex align-items-center justify-content-between">
              <h3 className="mb-0 ">Vendor Engineer Edit</h3>
              <button className="btn btn-warning btn-sm" onClick={closeModal}>
                Status
              </button>
            </CardHeader> */}
            <CardBody>
              <Form>
                <div className="bg-transparent d-flex align-items-center justify-content-between">
                  <a href="./employee-list" className="text-white mb-3">
                    <Button size="sm" color="default" type="button">
                      Back
                    </Button>
                  </a>
                  <button
                    className="btn btn-warning btn-sm"
                    onClick={closeModal}
                  >
                    Status
                  </button>
                </div>
                <h6 className="heading-small text-muted mb-4">
                  Engineer Information
                </h6>
                <div className="px-lg-4">
                  <Row>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-engineer-name"
                        >
                          Engineer Name
                        </label>
                        <Input
                          id="input-engineer-name"
                          type={"text"}
                          placeholder={"Engineer Name"}
                          defaultValue={getVendorEmployee?.emp?.name}
                          onChange={(e) => {
                            handleEditEO("name", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-employee-email"
                        >
                          Engineer Email
                        </label>
                        <Input
                          id="input-engineer-email"
                          type={"text"}
                          placeholder={"Engineer Email"}
                          defaultValue={getVendorEmployee?.emp?.email}
                          onChange={(e) => {
                            handleEditEO("email", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-engineer-phone"
                        >
                          Engineer Phone
                        </label>
                        <Input
                          id="input-engineer-phone"
                          type={"tel"}
                          placeholder={"Engineer phone"}
                          defaultValue={getVendorEmployee?.emp?.phone}
                          onChange={(e) => {
                            handleEditEO("phone", e?.target?.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="12">
                      <Button
                        color="default"
                        onClick={handleUpdateVendorEngineerSubmit}
                      >
                        Update Vendor Engineer
                      </Button>
                      <a className="btn btn-danger" href="./employee-list">
                        Cancel
                      </a>
                    </Col>
                  </Row>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      )}
    </>
  );
}

export default VendorEngineerEdit;
